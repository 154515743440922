import * as React from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";
import PolicyDescription from "../_components/PolicyDescription/PolicyDescription";

const Terms = () => {
    return (
        <Layout title="Terms & Conditions" hideFooterNavigation={false}>


            <hr></hr>

            <div id="terms">
                <MainTitle>TERMS AND CONDITIONS / OFFICIAL RULES</MainTitle>

                <MainTitle secondary>GLIDE AT BATTERSEA POWER STATION 2023/2024 (THE “EVENT”)</MainTitle>

                <PolicyDescription>
                    The Event, held at Battersea Power Station, London (“Site”) is owned by Battersea Power Station
                    Development Company Limited (“BPSDC”), a company incorporated at St Paul’s Gate, New Street, St
                    Helier, JE4 8ZB, Jersey with company number 08150687 and registered office address at 1 Village
                    Courtyard, Circus West Village, Battersea, London, United Kingdom, SW11 8AH. The Event is operated
                    on behalf of BPSDC by International Management Group (UK) Limited (“IMG”), a company incorporated in
                    England and Wales with company number 00946580 and with registered office at Building 6, Chiswick
                    Park, 566 Chiswick High Road, London W4 5HR.

                    <br/>These terms and conditions (“Terms and Conditions”) apply to “The Event” and the QR code
                    attached or displayed via the product or service (“The Product”) as marketed by the exhibitor. Upon
                    registering and providing your details, you (“the Participant”) will be eligible to claim a Gift
                    from the “The Event” brand or the licensed product manufacturer (or vendors or exhibitors). This
                    offer applies to that Product which has its own QR code.

                    <br/> 1. The Registration Giveaway Program (hereafter referred to as “the Giveaway Program”) is
                    promoted by IMG. The Giveaway Program is administered on Promoter’s behalf by Fabacus Services
                    Limited, 12 Soho Square - 2nd Floor, London, UNITED KINGDOM W1D 3QF (“the Administrator”).

                </PolicyDescription>

                <MainTitle secondary>Eligibility</MainTitle>

                <PolicyDescription>
                    2. The Giveaway Program is open to individuals aged 18 years or over, excluding employees and their
                    immediate families (defined as parents, siblings, children & spouse regardless of where they live)
                    of the Promoter or Administrator, their affiliated companies, their agents or anyone professionally
                    connected with the Rewards Program. Participation in the Giveaway Program is only open to legal
                    residents of the United Kingdom, who are 13 years of age or older. Those aged under 18 will not have
                    their data stored.

                    <br/>3. The Giveaway Program begins and ends as designated on the landing page of the Event (“the
                    Giveaway Program Period”). Any registrations before or after these times will not be considered as
                    eligible for the applicable Gifts in that Giveaway Program Period.
                </PolicyDescription>

                <MainTitle secondary>Submission Process</MainTitle>

                <PolicyDescription>
                    4. Participants that register at the Event, via the Product and submit any additional information as
                    required for participation will be entered into the Giveaway Program. All Participants are subject
                    to all notices posted online including but not limited to the Administrator’s Cookie Policy and
                    Privacy Policy. In the event of a dispute, online submissions will be deemed to have been submitted
                    by the Authorized Account Holder of the email address provided at the time of submission.
                    "Authorized Account Holder" means the natural person who is assigned to an email address by an
                    Internet access provider, online service provider, or other organization that is responsible for
                    assigning email addresses for the domain associated with the provided email address.
                    <br/>5. The Promoter and Administrator reserve the right to verify the eligibility of all
                    Participants.

                    <br/>6. Incomplete, incorrect, or otherwise invalid submissions will not be considered.
                    <br/>7. The Promoter and Administrator reserve the right to de-activate a QR code without advance
                    notice if it is reused, shared, or misused by a Participant.

                    <br/>8. Bad faith or fraudulent submissions will be invalidated. Submissions generated by script,
                    macro or other automated means and submissions by any means which subvert the submission process
                    will be void.
                    <br/>9. The Promoter reserves the right to exclude Participants and withhold the Offers and Prizes
                    for violation of any of these Terms and Conditions. Any attempt by any person to deliberately
                    undermine the legitimate operation of the Giveaway Program may be a violation of criminal and civil
                    law, and, should such an attempt be made, Promoter and Administrator reserve the right to seek
                    damages from any such person to the fullest extent permitted by law. Promoter’s and Administrator’s
                    failure to enforce any of these Terms and Conditions shall not constitute a waiver.

                    <br/>10. The decision of the Promoter to exclude Participants or withhold the Offers and Prizes is
                    final and no correspondence will be entered into.
                    <br/>11. The Promoter and Administrator will not be held responsible or liable for any technical,
                    hardware, software, server, website or other failures or damage of any kind, to the extent that this
                    prevents the Participant from or otherwise obstructs the Participant to enter in the Giveaway
                    Program. Submissions made by fax, telephone or email will not be accepted. Illegible, incomplete or
                    altered submission forms will be deemed void, as will submissions not completed in accordance with
                    these Terms and Conditions.
                </PolicyDescription>

                <MainTitle secondary>Gifts</MainTitle>

                <PolicyDescription>
                    12. The Offers and Gifts are as designated on the landing page. Participant will receive one or
                    several offers and/or Gifts among the Offers and Gifts displayed on the Landing Page, which are:
                    <br/>50 pairs of tickets to Glide at Battersea Power Station, estimated retail value £1,250. 100
                    pairs of tickets to Taste of London, estimated retail value £4,500. 100 drinks vouchers at Glide at
                    Battersea Power Station, estimated retail value £750 . 100 Funfair tokens at Glide at Battersea
                    Power station, estimated retail value £500. 15% off full price items at Loci, retail value dependent
                    on purchase. 20% off Nobody’s Child.com, retail value dependent on purchase.
                    <br/>Total estimated retail value of Offers and Gifts: £7,000
                </PolicyDescription>

                <MainTitle secondary>INPUT GIFTS AND OFFERS</MainTitle>

                <PolicyDescription>
                    13. There are no cash alternatives to the Gifts. The Gifts are non-transferable. Promoter and
                    Administrator wish to advise Participants in advance that some Gifts may not be available in some
                    parts of the world. ALL GIFTS ARE AWARDED “AS IS” WITHOUT ANY WARRANTY OF ANY KIND.
                    <br/>14. In the event of unforeseen circumstances, the Promoter reserves the right to offer an
                    alternative gift of equal or greater value should an original gift become unavailable.
                    <br/>15. The value of any Gifts is as designated or is the ordinary market or retail value.
                    Participants are responsible for all taxes and fees and phone service charges associated with
                    receipt and/or use of a Gift.
                    Privacy and Data Protection

                    <br/>16. Any personal data relating to Participants will be used in accordance with Administrator’s
                    Cookie Policy and Privacy Policy at
                    <a href="https://glidebattersea.xela.co/privacy/"
                       target="_blank">https://glidebattersea.xela.co/privacy/</a>.
                    <br/>17. During the Giveaway Program Period(s) and until such time that Promoter and Administrator
                    are no longer offering the Giveaway Program, Administrator will be the Controller of the personal
                    data of Participants, as Controller is defined in the EU General Data Protection Regulation 2016/679
                    of the European Parliament and of the Council (“GDPR”) and any applicable national legislation
                    (including national legislation implementing the GDPR), in each case as amended, replaced or
                    superseded from time to time, and all applicable legislation protecting the fundamental rights and
                    freedoms of persons and their right to privacy with regard to the processing of personal data. At
                    such time that the Giveaway Program Period(s) have all expired, Promoter will become the Controller
                    of the personal data of Participants.

                </PolicyDescription>

                <MainTitle secondary>General</MainTitle>

                <PolicyDescription>
                    18. The Promoter reserves the right to cancel, suspend and/or modify the Giveaway Program or amend
                    these Terms and Conditions at any time for any reason and without prior notice. Any amendment to the
                    Terms and Conditions will be posted on this website.
                    <br/>19. Except where expressly stated to the contrary, images, graphics, content, logos,
                    trademarks, and any and all other intellectual property appearing on this website or otherwise in
                    connection with the Giveaway Program are the property of Promoter, Administrator, or are used with
                    permission of third parties and may not be copied, modified or used without the prior written
                    consent of the respective owner. Participants should not share or copy any of the material made
                    available through the Giveaway Program or reproduce/rebroadcast any of the images, recordings or
                    material without specific, advance, written permission from the respective owner.
                    <br/>20. By participating in this Giveaway Program, you agree to participate in any reasonable
                    marketing and promotional activities required by the Promoter or Administrator. By participating,
                    you agree to release and hold harmless Promoter, Administrator, and their respective parents,
                    subsidiaries, affiliates, suppliers, distributors, advertising/promotion agencies, and gift
                    suppliers, and each of their respective parent companies and each such company’s officers,
                    directors, employees and agents (collectively, the “Released Parties”) from and against any claim or
                    cause of action, including, but not limited to, personal injury, death, or damage to or loss of
                    property, arising out of participation in the Giveaway Program or receipt or use or misuse of any
                    Gift.
                    <br/>21. The Released Parties are not responsible for: (1) any incorrect or inaccurate information,
                    whether caused by Participant, printing errors or by any of the equipment or programming associated
                    with or utilized in the Giveaway Program; (2) technical failures of any kind, including, but not
                    limited to malfunctions, interruptions, or disconnections in phone lines or network hardware or
                    software; (3) unauthorized human intervention in any part of the submission process or the Giveaway
                    Program; (4) technical or human error which may occur in the administration of the Giveaway Program;
                    or (5) any injury or damage to persons or property which may be caused, directly or indirectly, in
                    whole or in part, from participation in the Giveaway Program or receipt or use or misuse of any
                    Gift. Promoter and Administrator are not responsible for misdirected or undeliverable submissions or
                    for any technical problems, malfunctions of computer systems, servers, providers, hardware/software,
                    lost or unavailable network connections or failed, incomplete, garbled or delayed computer
                    transmission or any combination thereof. Promoter and Administrator are not responsible for any
                    typographical/other error in submissions, or for any liability for damage to any computer system
                    resulting from participation in, accessing or downloading information in connection with this
                    Giveaway Program. EXCEPT WHERE PROHIBITED, PARTICIPANT AGREES THAT: (1) ANY AND ALL DISPUTES, CLAIMS
                    AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THIS REWARDS PROGRAM OR ANY GIFT AWARDED SHALL
                    BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION, AND EXCLUSIVELY BY THE COURTS
                    OF ENGLAND; (2) ANY AND ALL CLAIMS, JUDGMENTS AND AWARDS SHALL BE LIMITED TO ACTUAL OUT-OF-POCKET
                    COSTS INCURRED, INCLUDING COSTS ASSOCIATED WITH PARTICIPATION IN THIS REWARDS PROGRAM, BUT IN NO
                    EVENT ATTORNEYS’ FEES; AND (3) UNDER NO CIRCUMSTANCES WILL PARTICIPANT BE PERMITTED TO OBTAIN AWARDS
                    FOR, AND PARTICIPANT HEREBY WAIVES ALL RIGHTS TO CLAIM, INDIRECT, PUNITIVE, INCIDENTAL AND
                    CONSEQUENTIAL DAMAGES AND ANY OTHER DAMAGES, OTHER THAN FOR ACTUAL OUT-OF-POCKET EXPENSES, AND ANY
                    AND ALL RIGHTS TO HAVE DAMAGES MULTIPLIED OR OTHERWISE INCREASED.
                    <br/>22. By participating in the Giveaway Program, Participants will be deemed to have read and
                    accepted these Terms and Conditions.
                    <br/>23. These Terms and Conditions are governed by English law. In the event of any discrepancy or
                    inconsistency between the English law and the local law of a Participant, the Giveaway Program and
                    these Terms and Conditions, the English law version shall prevail. The Giveaway Program is subject
                    to all applicable international, federal, state, and local laws and regulations and is void where
                    prohibited by law.

                </PolicyDescription>
            </div>


        </Layout>
    );
};

export default Terms;
