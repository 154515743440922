export default class Database {
  static getQrParams = () => {
    return this._getFromLocalStorage("qrParams");
  };

  static setQrParams = (value) => {
    this._saveToLocalStorage("qrParams", value);
  };

  static removeQrParams = () => {
    this._removeFromLocalStorage("qrParams");
  };

  static getUserToken = () => {
    return this._getFromLocalStorage("userToken");
  };

  static setUserToken = (value) => {
    this._saveToLocalStorage("userToken", value);
  };

  static removeUserToken = () => {
    this._removeFromLocalStorage("userToken");
  };

  static getCookieAccepted = () => {
    return this._getFromLocalStorage("cookieAccepted");
  };

  static setCookieAccepted = (value) => {
    this._saveToLocalStorage("cookieAccepted", value);
  };

  static removeCookieAccepted = () => {
    this._removeFromLocalStorage("cookieAccepted");
  };

  static getRewardsMessage = () => {
    return this._getFromLocalStorage("rewardsMessage");
  };

  static setRewardsMessage = (value) => {
    this._saveToLocalStorage("rewardsMessage", value);
  };

  static removeRewardsMessage = () => {
    this._removeFromLocalStorage("rewardsMessage");
  };

  static getRewardsList = () => {
    return this._getFromLocalStorage("rewardsList");
  };

  static setRewardsList = (value) => {
    this._saveToLocalStorage("rewardsList", value);
  };

  static removeRewardsList = () => {
    this._removeFromLocalStorage("rewardsList");
  };

  static _getFromLocalStorage = (key) => {
    if (typeof localStorage !== undefined) {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch (e) {
        //
      }
    }

    return null;
  };

  static _saveToLocalStorage = (key, value) => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };

  static _removeFromLocalStorage = (key) => {
    if (typeof localStorage !== "undefined") {
      localStorage.removeItem(key);
    }
  };
}
