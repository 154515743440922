import * as React from "react"

export default function ShareActionBlock() {
    return (
        <div className="share-with-friends-block">
        <div className="share-block-action">
            <div className="block-content">
                <h2>SHARE WITH FRIENDS</h2>
            </div>
            <div className="share-item-list">

                <div className="share-item">
                    <a href="https://www.facebook.com/glideatbps/" target="_blank">
                        <img src="/images/social-icons/facebook.svg" alt=""/>
                    </a>
                </div>

                <div className="share-item">
                    <a href="https://www.instagram.com/glideatbps/" target="_blank">
                        <img src="/images/social-icons/instagram.svg" alt=""/>
                    </a>
                </div>

                <div className="share-item">
                    <a href="https://twitter.com/glideatbps" target="_blank">
                        <img src="/images/social-icons/twitter.svg" alt=""/>
                    </a>
                </div>
                <div className="share-item">
                    <a href="https://www.tiktok.com/@glideatbps" target="_blank">
                        <img src="/images/social-icons/tiktok.svg" alt=""/>
                    </a>
                </div>

                {/*<div className="share-item">*/}
                {/*    <a href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fglidebatterseapowerstation.co.uk%2F&title=Hey!%20Check%20out%20London%E2%80%99s%20coolest%20ice%20rink%20this%20Christmas." target="_blank">*/}
                {/*        <img src="/images/social-icons/linkedin.svg" alt=""/>*/}
                {/*    </a>*/}
                {/*</div>*/}

                {/*<div className="share-item">*/}
                {/*    <a href="mailto:?subject=I%20think%20you%27ll%20love%20Glide%20at%20Battersea%20Power%20Station&body=Hey!%20Check%20out%20London%E2%80%99s%20coolest%20ice%20rink%20this%20Christmas%3A%20https%3A%2F%2Fglidebatterseapowerstation.co.uk%2F" target="_blank">*/}
                {/*        <img src="/images/social-icons/mail.svg" alt=""/>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </div>
        </div>
    )
}
