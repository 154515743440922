import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import "./styles.scss";
import Database from "../../_utils/Database";

const Header = ({hideSubNav}) => {
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        if (Database.getUserToken()) {
            setIsLogged(true);
        }
    }, [isLogged]);

    return (
        <>
            <nav className="header">
                <div className="header-content">
                    <Link to="/home" className="logo-wrap">
                        <img
                            src="/images/glide/logo-top.png"
                            className="logo"
                            alt="Taste"
                        />
                    </Link>
                </div>

                {!hideSubNav && (
                    <div className="header-nav">
                        <Link to="/home">
                            Home and What’s On
                        </Link>
                    </div>

                )
                }
            </nav>

            {/* <UserMenu isLogged={isLogged}/> */}
        </>
    );
};
export default Header;
