import React from "react";
import "./styles.scss";

const PolicyDescription = ({ children, sentence }) => {
    return (
        <>
        {sentence ? (
            <p className="sentence">{children}</p>
        ) : (
            <p className="policy-description">{children}</p>
        )}
        </>
    );
};

export default PolicyDescription;
