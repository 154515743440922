import React from "react";
import "./styles.scss";

const MainTitle = ({
    children,
    secondary,
    className
}) => {
    return (
        <>
        {secondary ? (
            <h2 className={`secondary-title ${className}`}>
                {children}
            </h2>
        ) : (
            <h1 className={`main-title ${className}`}>
                {children}
            </h1>
        )}
        </>
    );
};

export default MainTitle;
