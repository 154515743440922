import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import "./styles.scss";
import Database from "../../_utils/Database";

export default function CookiesMessage() {
    const [accepted, setAccepted] = useState(false);

    useEffect(() => {
        setAccepted(Database.getCookieAccepted());
    }, []);

    const handleClick = () => {
        setAccepted(true);
        Database.setCookieAccepted(true);
    };

    return (
        !accepted ? (
            <div className="cookies-message">
                <div className="modal fade show" tabIndex="-1" style={{ "display": "block" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Cookies
                                </h5>
                            </div>

                            <div className="modal-body">
                            We use cookies to provide you with a personalised experience when you visit our website. By continuing to use this site, you agree to our
                                &nbsp;

                                <Link to="/cookies/">
                                    Cookie Policy
                                </Link>
                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleClick}>
                                    Accept and Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    );
}
