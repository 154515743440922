import * as React from "react";
import "./styles.scss";
import DevTools from "../DevTools/DevTools";
import {Link} from "gatsby";
import ShareActionBlock from "../ShareActionBlock/ShareActionBlock";

export default function Footer({hideFooterNavigation, hideSocial}) {
    return (
        <>
            <div className="footer">
                {!hideSocial ? (<ShareActionBlock></ShareActionBlock>) : null}

                {!hideFooterNavigation ? (
                    <div className="accordion-tabs">
                        <Link
                            to="/terms/">
                            Terms and Conditions
                        </Link>

                        <Link
                            to="/privacy/">
                            Privacy Policy
                        </Link>

                        <Link
                            to="/cookies/">
                            Cookie Policy
                        </Link>

                        <Link
                            to="/contacts/">
                            Contact Us
                        </Link>
                    </div>
                ) : null}

                <img
                    src="/images/glide/logo-desktop.svg"
                    className="logo"
                    alt="Taste"/>

                {/* <p className="footer-fullyear">
                    &copy; {[new Date().getFullYear()]}
                </p> */}

                <p className="footer-fullyear powered">
                    Powered by
                    <img
                        src="/images/fabacus-logo-white.png"
                        className="logo"
                        alt="Fabacus"/>
                </p>
            </div>

            <DevTools/>
        </>
    );
}
